<template>
	<div class="user__view">
		<div class="header">
			<h2>#{{user.id}} <span>Modifier l'utilisateur</span></h2>
			<v-btn color="white--text" class="elevation-0" @click="$router.push({path: '/user/' + $route.params.userId + '/edit-password'})" tile>
				<v-icon color="white">
					mdi-form-textbox-password
				</v-icon>
				Modifier le mot de passe
			</v-btn>
		</div>
		<div class="control">
			<v-btn color="#272727" class="white--text" @click="retour()" tile x-large>
				Retour
			</v-btn>
			<v-btn color="green" class="white--text" :loading="loading" @click="validate()" tile x-large>
				Sauvegarder
			</v-btn>
		</div>
		<div class="displayer">
			<div class="right">
				<div class="user__card">
					<div class="user">
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
						>
							<v-text-field v-model="user.login" :rules="loginRules" label="Identifiant"></v-text-field>
							<v-text-field v-model="user.email" :rules="emailRules" label="E-mail"></v-text-field>
							<v-text-field v-model="user.cab" :rules="cabRules" label="CAB"></v-text-field>
							<v-switch 
								v-model="user.review"
								label="Vérification des articles sur l'administration Maax avant publication"
							></v-switch >
							<v-switch 
								v-model="user.review_annuaire"
								label="Vérification des inscriptions aux annuaires sur l'administration Maax avant publication"
							></v-switch >
						</v-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { utils } from '../utils.js'

export default {
	name: 'UserAdd',
	data() {
		return {
			valid: false,
			loginRules: [
				v => !!v || 'Champs requis'
			],
			passwordRules: [
			],
			emailRules: [
				v => !!v || 'Champs requis',
				v => /.+@.+\..+/.test(v) || 'E-mail invalide',
			],
			cabRules: [
				v => !!v || 'Champs requis'
			],
			user: {
				id: '',
				login: '',
				password: '',
				email: '',
				review: false,
				review_annuaire: false,
				cab: 'EXT'
			},
			dialog: false,
			loading: false
		}
	},
	methods: {
		...mapActions("user", [
            "editUserAction",
            "getUserAction"
        ]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		validate () {
			if (this.$refs.form.validate() == false) {
				return
			}
			this.editUser()
		},
		retour() {
			this.$router.push({path: '/user/' + this.$route.params.userId})
		},
		editUser() {
			const _this = this;
			this.loading = true;
			this.editUserAction({
				user: this.user.id,
				login:  this.user.login,
				email: this.user.email,
				review: this.user.review,
				review_annuaire: this.user.review_annuaire,
				cab: this.user.cab,
				password: ''
			})
			.then(() => {
				_this.addSuccess("L'utilisateur a été modifié.")
				_this.retour();
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		loadUser() {
			const _this = this
			this.getUserAction(this.$route.params.userId)
			.then(function (res) {
				_this.user.id = res.data.id
				_this.user.cab = res.data.cab
				_this.user.login = res.data.login
				_this.user.email = res.data.email
				_this.user.review = res.data.review
				_this.user.review_annuaire = res.data.review_annuaire
			})
			.catch(() => {
				_this.retour()
			})
		},
	},
	created () {
		this.loadUser()
	}
}
</script>

<style lang="scss" scoped>
.user__view {
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dbdbdb;

		h2 {
			font-weight: normal;
			margin-bottom: 20px;
			width: 100%;
			margin: 0;

			span {
				font-size: 16px;
				color: #969696;
			}
		}
	}

	.control {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;

		.v-btn {
			width: 50%;
		}
	}

	.displayer {
		display: flex;
		height: 100%;

		.left {
			width: 25%;
			min-height: 100vh;
			border-right: 1px solid #dbdbdb;
		}

		.right {
			width: 75%;
			padding: 1% 2% 5% 2%;
			height: fit-content;

			.user__card {
				padding: 10px;

				h4 {
					color: #c1c1c1;
					font-weight: normal;
				}

				.user {
					margin-top: 2%;

					.swicth__status {
						display: flex;
						align-items: center;

						h3 {
							margin-right: 10px;
						}
					}

					.info__image {
						margin-top: 15px;
					}

					.upload__image {
						margin-top: 25px;
						.displayer {
							display: flex;
							gap: 50px;
						}

						.grid__gallery {
							width: 50%;
							border-right: 2px dashed #c7c7c7;
							min-height: 250px;

							.row {
								padding: 10px;
							}

							.gallery {
								cursor: pointer;
								position: relative;

								.selected {
									position: absolute;
									top: 0;
									right: 0;
									border-radius: 50%;
									background: green;
									z-index: 2;
									width: 25px;
									height: 25px;
									display: flex;
									align-items: center;
									justify-content: center;

									i {
										font-size: 15px;
									}
								}
							}
						}

						.upload__gallery {
							width: 50%;
						}

						.loading__file {
							margin-top: 50px;
						}
					}

				}
			}
		}
	}
}
</style>